.windSpeedChart .modebar {
    left: 42%;
}
 .windSpeedChart .js-plotly-plot {
     margin: 10px;
     width: 100%;
     height: 420px;
 }

.notFoundWindData {
    height: 420px;
}



